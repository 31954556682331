<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">充值余额</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="输入关键字" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					:props="treeProps" @node-click="areaSelected" style="margin: 10px;" highlight-current>
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" @selection-change="handleSelectionChange"
				@row-click="rowclick" row-key="id" default-expand-all :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
				>
				<el-table-column fixed prop="roomId" label="roomId" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="200" />
				<el-table-column prop="familyPhone" label="户主电话" width="150" align="center" />
				<el-table-column prop="balance" label="余额" width="100" />
				<el-table-column />
				<el-table-column fixed="right" label="操作" width="100" align="left">
					<template #default="scope">
						<el-button size="mini" @click.stop="charge(scope.row)" type="success" class="buttonfortable">
							充 值
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
	
	<el-dialog width="800px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-form-item prop="oldBalance" label="原始余额" :label-width="formLabelWidth">
				<el-input v-model="formdata.oldBalance" autocomplete="off" :style="formContentStyle" readonly >
					<template #append>元</template>
				</el-input>
			</el-form-item>
			<el-form-item prop="newBalance" label="新余额" :label-width="formLabelWidth">
				<el-input v-model="formdata.newBalance" autocomplete="off" :style="formContentStyle" readonly >
					<template #append>元</template>
				</el-input>
			</el-form-item>
			<el-form-item prop="dealMoney" label="充值金额" :rules="[{ required: true, message: '请输入充值金额',trigger:'blur' }]"
				:label-width="formLabelWidth">
				<el-input ref="txtPayMoneyRef" v-model="formdata.dealMoney" autocomplete="off" :style="formContentStyle" type="number"
					@blur="calcBalance" @focus="selectAllText($event)" autofocus >
					<template #append>元</template>
				</el-input>
			</el-form-item>
			<el-form-item prop="payType" :rules="[{ required: true, message: '请输选择缴费方式' }]" label="缴费方式"
				:label-width="formLabelWidth">
				<el-select v-model="formdata.payType" placeholder="请选择缴费方式" :style="formContentStyle">
					<el-option v-for="item in payTypes" :key="item.value" :label="item.label" :value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item prop="description" label="描述" :label-width="formLabelWidth">
				<el-input v-model="formdata.description" autocomplete="off" :style="formContentStyle" type="textarea" maxlength="200"
					:rows="3" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform"> 充 值
				</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	let payTypes = reactive([{
			label: '现金',
			value: '1'
		},
		{
			label: '微信',
			value: '2'
		},
		{
			label: '支付宝',
			value: '3'
		},
		{
			label: '银联',
			value: '4'
		}
	])
	// 定义表单标题
	let dialogtitle = ref("充值账户余额")
	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	const txtPayMoneyRef = ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('200px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:400px')
	// 定义表单数据
	let formdata = reactive({
		oldBalance: 0,
		dealMoney: 0.0,
		newBalance: 0,
		description: '',
		payType: '1',
		roomId: ''
	})
	let currentRow = ref()
	let paymentId = ref('')
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	// 定义查询参数
	let params = reactive({
		keyword: ''
	})


	// 定义表格数据
	let tableDatas = ref([])
	let roomDatas = ref([])
	let areaDatas = ref([])
	let saveDatas = ref([])

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}

	const areaSelected = (area) => {
		let loading = proxy.$tools.ShowLoading();
		setTimeout(()=>{
			tableDatas.value = []
			let grade = area.grade
			let id = area.id
			if (grade == "1") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId1 == id)
			} else if (grade == "2") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId2 == id)
			} else if (grade == "3") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId3 == id)
			}
			loading.close();
		},300)
	}


	const calcBalance = () => {
		if (formdata.dealMoney) {
			formdata.dealMoney=parseFloat(parseFloat(formdata.dealMoney).toFixed(2));
			formdata.newBalance = parseFloat((parseFloat(formdata.oldBalance) + formdata.dealMoney).toFixed(2));
		}
	}


	const selectAllText = (event) => {
		event.currentTarget.select();
	}

	const charge = (row) => {
		formdata.description = '';
		formdata.dealMoney = 0;
		formdata.oldBalance = row.balance
		formdata.roomId = row.roomId;
		formdata.newBalance = row.balance
		dialogtitle.value='('+row.areaName1+'-'+row.areaName2+'-'+row.areaName3+'-'+row.roomName+'-'+row.roomNum+')充值账户余额';
		dialogVisible.value = true;
		let timer = setTimeout(() => {
			txtPayMoneyRef.value.focus();
			clearTimeout(timer)
		}, 500);
	}
	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		console.log(formdata)
		form.validate(async (valid) => {
			if (valid) {
				let loading = proxy.$tools.ShowLoading();
				let response = await GetServerData('ChargeBalance/ChargeBalance', {
					"roomId": formdata.roomId,
					"dealMoney": formdata.dealMoney,
					"payType": formdata.payType,
					"description": formdata.description
				})
				if (response.status == 1) {
					proxy.$tools.ShowMessageDuration('充值成功', true, proxy.$config.showImportantTipsSecond)
					paymentId.value = response.data
					for (let j = 0; j < tableDatas.value.length; j++) {
						if (tableDatas.value[j].roomId == formdata.roomId) {
							tableDatas.value[j].balance = formdata.newBalance
							break;
						}
					}
					dialogVisible.value = false
				} else {
					proxy.$tools.ShowMessageDuration(response.message, false,proxy.$config.showImportantTipsSecond)
				}
				loading.close()
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}

	const search = () => {
		if (params.keyword.length > 0) {
			let loading = proxy.$tools.ShowLoading();
			setTimeout(()=>{
				tableDatas.value = []
				tableDatas.value = roomDatas.value.filter(room =>
					room.areaName1?.indexOf(params.keyword) > -1 ||
					room.areaName2?.indexOf(params.keyword) > -1 || 
					room.areaName3?.indexOf(params.keyword) > -1 || 
					room.roomName?.indexOf(params.keyword) > -1 || 
					room.roomNum?.indexOf(params.keyword) > -1 || 
					room.familyName?.toString().indexOf(params.keyword) > -1 || 
					room.familyPhone?.toString().indexOf(params.keyword) > -1
				)
				loading.close();
			},300)
		} else {
			proxy.$tools.ShowMessage('请输入关键字', false)
		}
	}


	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('ChargeBalance/QueryAllRooms')
		if (response.status == 1) {
			roomDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取表格数据函数
	const GetAreaData = async () => {
		let response = await GetServerData('ChargeBalance/QueryAllAreas')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
		await GetAreaData()
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					425) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) +
			'px'
	})
</script>

<style scoped lang="less">
</style>
